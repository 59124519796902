import React from 'react'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import { Flex, Box, Heading, Text } from 'rebass'
import { Absolute } from '@rebass/position'
import styled from 'styled-components'
import { format, isFuture, isPast, isEqual, addDays } from 'date-fns'
import Embed from '../components/embed'

const EventLink = styled(Link)`
  display: block;
  color: ${props => (props.linkColor ? props.linkColor : 'white')};
  text-decoration: none;
  transition: all 0.5s ease;

  .gatsby-image-wrapper {
    transition: all 0.5s ease;
  }

  &:hover {
    transform: translateY(-4px);

    .gatsby-image-wrapper {
      filter: grayscale(0) !important;
    }
  }

  h2 {
    text-decoration: underline;
  }
`

export const EventList = ({ events, home }) => {
  let upcomingEvents = events.filter(event =>
    isFuture(addDays(event.node.endDate, 7))
  )

  if (home) {
    upcomingEvents = upcomingEvents.reverse()
  }

  return (
    <Box mb={[5, null, 6]}>
      {upcomingEvents.length > 0 && !home && (
        <Heading
          as="h2"
          fontSize={[4, 5, 6]}
          fontWeight="normal"
          textAlign="center"
          mb={[3, 4, 5]}
          color={home ? 'white' : 'black'}
        >
          Tulevat kurssit
        </Heading>
      )}
      {upcomingEvents.length > 0 &&
        upcomingEvents.map(({ node: event }, i) => (
          <Box key={i} mb={[4, null, 5]}>
            <EventLink to={`/kurssit/${event.slug}`}>
              <Flex flexDirection={['column', null, 'row']}>
                <Box width={[1, null, 2 / 5]} flex="none">
                  {event.featuredImages && (
                    <Embed ratio={0.75}>
                      <Img
                        fluid={event.featuredImages[0].fluid}
                        alt={event.featuredImages[0].description}
                        style={{
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          width: '100%',
                          height: '100%',
                          borderRadius: '8px',
                        }}
                      />
                      <Absolute
                        top={['auto', null, 0]}
                        right={['auto', null, 0]}
                        bottom={[0, null, 'auto']}
                        left={[0, null, 'auto']}
                      >
                        <Box
                          py={2}
                          pr={3}
                          pl={home ? 3 : [0, 0, 3]}
                          bg="white"
                          style={{ borderRadius: '0 8px 0 8px' }}
                        >
                          <Text fontSize={[1, 2]} color="black">
                            {isEqual(event.startDate, event.endDate)
                              ? format(event.endDate, 'D.M.YYYY')
                              : format(event.startDate, 'D.M.') +
                                '–' +
                                format(event.endDate, 'D.M.YYYY')}
                          </Text>
                        </Box>
                      </Absolute>
                    </Embed>
                  )}
                </Box>
                <Box flex="auto" ml={[0, 0, 4]} my={[3, null, 0]}>
                  <Heading
                    color={home ? 'white' : 'black'}
                    fontWeight="normal"
                    fontSize={[4, 5, null, 6]}
                    mb={3}
                  >
                    {event.name}
                  </Heading>
                  {event.description && (
                    <Text
                      color={home ? 'white' : 'black'}
                      fontSize={[1, 2, 3, '22px']}
                    >
                      {event.description.description}
                    </Text>
                  )}
                </Box>
              </Flex>
            </EventLink>
          </Box>
        ))}
    </Box>
  )
}

export const PastEventList = ({ events }) => {
  const pastEvents = events
    .filter(event => isPast(addDays(event.node.endDate, 7)))
    .reverse()

  return (
    <div>
      {pastEvents.length > 0 && (
        <Heading
          as="h2"
          fontSize={[4, 5, 6]}
          fontWeight="normal"
          textAlign="center"
          mb={[3, 4, 5]}
          children="Menneet kurssit"
        />
      )}
      <Flex flexWrap="wrap" mx={-3}>
        {pastEvents.length > 0 &&
          pastEvents.map(({ node: event }, i) => (
            <Box width={[1, 1 / 2, 1 / 3]} p={3} key={i}>
              <EventLink to={`/kurssit/${event.slug}`}>
                <Box>
                  {event.featuredImages && (
                    <Embed ratio={0.75}>
                      <Img
                        fluid={event.featuredImages[0].fluid}
                        alt={event.featuredImages[0].description}
                        style={{
                          position: 'absolute',
                          left: 0,
                          top: 0,
                          width: '100%',
                          height: '100%',
                          borderRadius: '8px',
                          filter: 'grayscale(1)',
                        }}
                      />
                      <Absolute left={0} bottom={0}>
                        <Box
                          pt={2}
                          pr={3}
                          bg="white"
                          style={{ borderRadius: '0 8px 0 8px' }}
                        >
                          <Text fontSize={[1, 2]} color="black">
                            {isEqual(event.startDate, event.endDate)
                              ? format(event.endDate, 'D.M.YYYY')
                              : format(event.startDate, 'D.M.') +
                                '–' +
                                format(event.endDate, 'D.M.YYYY')}
                          </Text>
                        </Box>
                      </Absolute>
                    </Embed>
                  )}
                </Box>
                <Heading
                  color="black"
                  fontWeight="normal"
                  fontSize={[3, 4]}
                  mt={2}
                  children={event.name}
                />
              </EventLink>
            </Box>
          ))}
      </Flex>
    </div>
  )
}

export default EventList
