import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Box } from 'rebass'
import { Container, Hero, Layout } from '../components'
import { EventList, PastEventList } from '../components/eventList'
import config from '../utils/siteConfig'

const EventsPage = ({ data }) => (
  <Layout>
    <Helmet>
      <title>Kurssit – {config.siteTitle}</title>
      <meta name="description" content={config.siteDescription} />
      <meta property="og:title" content={`Kurssit – ${config.siteTitle}`} />
      <meta property="og:url" content={config.siteUrl} />
    </Helmet>

    <Hero title="Kurssit" />

    <Box py={6}>
      <Container maxWidth="72em">
        <EventList events={data.allContentfulEvent.edges} linkColor="black" />
        <Box my={4}>
          <PastEventList
            events={data.allContentfulEvent.edges}
            linkColor="black"
          />
        </Box>
      </Container>
    </Box>
  </Layout>
)

export default EventsPage

export const query = graphql`
  query eventsQuery {
    allContentfulEvent(
      filter: { eventType: { eq: "Weekend Course" } }
      sort: { order: ASC, fields: [startDate] }
    ) {
      edges {
        node {
          name
          slug
          eventType
          startDate
          endDate
          description {
            description
          }
          body {
            body
          }
          featuredImages {
            title
            description
            fluid(maxWidth: 1440, quality: 60) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
